<template>
  <div class="about">
    <h1>This is an about page</h1>

    <b-card>
      <b-button>Button</b-button>
      <b-button variant="danger">Button</b-button>
      <b-button variant="success">Button</b-button>
      <b-button variant="outline-primary">Button</b-button>
    </b-card>
  </div>
</template>
